.SelectorWrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 15px;
}

.SelectorHeader{
    font-weight: 400;
    font-size: 20px;
    line-height: 132%;
    color: #303030;
}

.Selector{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    position: relative;

    width: 100%;
}

.SelectorSelected{
    width: 100%;
    padding: 15px;
    border-radius: 1000000000px;
    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);
    background: #fff;

    font-weight: 400;
    font-size: 16px;
    line-height: 132%;
    color: #6a6a6a;

    cursor: pointer;
    user-select: none;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    z-index: 2;
}

.SelectorMenu{
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 0;
    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);
    background: #fff;
    border-radius: 20px;
    padding: 0;
  

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 132%;
    color: #6a6a6a;
    opacity: 0;
    overflow: hidden auto;

    transition: 0.3s;
    z-index: 1;
}

.SelectorSelected svg{
    transition: 0.3s;
}

.SelectorMenu.Open{
    height: 200px;
    padding: 61px 15px 15px 15px;
    opacity: 1;

}

.SelectorPoint{
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
    padding: 10px;
    z-index: 0;
}

.SelectorPoint:hover{
    cursor: pointer;
    background: #ff5875;
    color: #fff;
}