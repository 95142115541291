.EpisodeCardWrapper{
    border: 1px solid #303030;
    border-radius: 10px;
    width: 50px;
    height: 50px;

    overflow: hidden;
}

.EpisodeCardWrapper img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}