.ScketchInputWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 15px;
    width: 100%;
}

.ScketchInputHeader{
    font-weight: 400;
    font-size: 20px;
    line-height: 132%;
    color: #303030;
}

.ScketchInputDescription{
    font-weight: 400;
    font-size: 16px;
    line-height: 132%;
    color: #6a6a6a;
}

.ScketchInputImgArea{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;


    border-radius: 40px;
    overflow: hidden;
}

.ScketchInputImgArea.Square{
    width: 217px;
    height: 217px;
}

.ScketchInputImgArea.Square img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.ScketchInputImgArea img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.ScketchInputImgArea input{
    position: absolute;
    opacity: 0;

    cursor: pointer;

    width: 100%;
    height: 100%;

}

.ScketchInputImgArea.Square{
    width: 217px;
    height: 217px;
}

@media (max-width: 720px){
    .ScketchInputImgArea.Square{
        width: 100%;
        height: 400px;
    }

    .ScketchInputImgArea{
        width: 100%;
    }
}