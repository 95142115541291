.ButtonBackRed{
    height: 49px;
    border: none;
    padding: 15px 35px;
    border-radius: 1000px;

    transition: 0.5s;

    color: #fff;

    cursor: pointer;

    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
    background: #ff2f55;
}

@media(hover :hover){
    .ButtonBackRed:hover{
        background: #ff5875;
    }
}