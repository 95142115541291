.SubscribeCardWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    gap: 10px;

    padding: 15px;

    /* box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);
    background: #fff;
    border-radius: 27px; */
}

.SubscribeCardWrapper button{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    padding: 11px 20px;
    background: #ff2f55;

    outline: none;
    border: none;
    cursor: pointer;

    font-weight: 700;
    font-size: 14px;
    color: #fff;
}

.SubscribeCardWrapper button:hover{
    background: #ff5875;
}

.PhotoSubscribeCard{
    position: relative;
    border: 1px solid #303030;
    border-radius: 100000002004087730000px;
    width: 89px;
    height: 89px;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.09), 0 10px 6px 0 rgba(0, 0, 0, 0.05), 0 18px 7px 0 rgba(0, 0, 0, 0.01), 0 29px 8px 0 rgba(0, 0, 0, 0);
    background: #4a6cf7;
    overflow: hidden;
}

.PhotoSubscribeCard img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}