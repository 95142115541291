.ComicsCardGenrePageWrapper{
   
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    position: relative;



    width: 147px;
    height: 206px;
}

.ComicsCardGenrePageWrapper img{
    position: absolute;
    top: 0;
    left: 0;
    width: 147px;
    height: 206px;
    border-radius: 27px;
    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);

}

.GenrePageComicsCardInfoContainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;

    overflow: hidden;
    width: 100%;
    height: 100%;

    gap: 15px;
}

.GenrePageComicsCardInfoContainerBody{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

}

.GenrePageComicsCardInfoContainerBodyTitle{
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 132%;
    color: #ffffff;
    hyphens: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;

    height: 100%;
    z-index: 0;

    display: flex;
    justify-content: flex-end;

    gap: 10px;

    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .8)), to(rgba(0, 0, 0, .01)));
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .8) 0, rgba(0, 0, 0, .01));
    text-shadow: 0 1px rgba(0, 0, 0, .7);

    padding: 10px 10px;

    border-radius: 27px;
}

.GenrePageComicsCardInfoContainerBodyTitle p{
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;


}

.GenrePageComicsCardInfoContainerBodyEpisodeContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 10px;
}

.GenrePageAddEpisodeItem{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
}

.GenrePageComicsCardButtonContainer{
    position: absolute;
    top: 30px;
    right: 30px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 10px;
}

.GenrePageComicsCardButtonContainer a{
    border: 1px solid #303030;
    border-radius: 13px;
    width: 49px;
    height: 49px;
    background: #f2f2f2;

    display: flex;
    justify-content: center;
    align-items: center;
}

.GenrePageComicsCardButtonContainer a svg{
    width: 21px;
    height: 21px;
}

.GenrePageComicsCardButtonContainer button{
    border: 1px solid #303030;
    border-radius: 13px;
    width: 49px;
    height: 49px;
    background: #f2f2f2;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.GenrePageComicsCardButtonContainer button svg{
    width: 21px;
    height: 21px;
}

.GenrePageComicsCardInfoStatistic{
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    gap: 20px;
}

.GenrePageComicsCardInfoStatisticView{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 132%;
    text-align: center;
    color: #ffffff;
}

@media (max-width: 480px){
    
    .ComicsCardGenrePageWrapperLink{
        width: 100%;
    }

    .ComicsCardGenrePageWrapper{
        width: 100%;
        height: 520px;
    }

    .ComicsCardGenrePageWrapper img{
        object-fit: cover;
        width: 100%;
        height: 520px;
    
    }
}