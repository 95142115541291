.FooterWrapper{
    height: 213px;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #ff2f55;
}

.FooterContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    width: 100%;
    height: 100%;
    max-width: 1920px;

    padding: 50px 100px;

    gap: 20px;
}

.FooterLogoWrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    height: 100%;
}

.FooterLogoDescription{
    font-weight: 700;
    font-size: 16px;
    line-height: 181%;
    color: #ffa8b8;
}

.FooterLinksWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    height: 100%;
}

.FooterLinksContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 30px;
}

.FooterLinksContainer a{
    font-weight: 700;
    font-size: 16px;
    line-height: 181%;
    color: #fff;
}

.FooterLinksContainer a:not(:last-child){
    padding-right: 30px;
    border-right: 1px solid #ffa8b8;
}

.FooterStoreLinksWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    height: 100%;
}

.FooterHeaderStoreLinks{
    font-weight: 700;
    font-size: 23px;
    line-height: 126%;
    color: #fff;
}

.FooterHeaderStoreLinks span{
    text-transform: uppercase;
    color: #303030;
}

.FooterImageLinks{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    gap: 20px;
}

.FooterSocialLinksContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    gap: 50px;
}

.FooterSocialLinksContainer a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    gap: 15px;

    font-weight: 700;
    font-size: 16px;
    line-height: 181%;
    color: #ffa8b8;
}

@media (max-width: 1280px){
    .FooterContainer{
        padding: 50px 50px;
    }
}

@media (max-width: 1177px){
    .FooterLinksContainer{
        flex-direction: column;
        gap: 10px;
    }

    .FooterLinksWrapper{
        flex-direction: row;
    }

    .FooterSocialLinksContainer{
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .FooterLinksContainer a:not(:last-child){
        border-right: none;
    }
}

@media (max-width: 1015px){
    .FooterWrapper{
        height: unset;
    }

    .FooterLogoWrapper{
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap: 20px;
    }

    .FooterLinksWrapper{
        width: 100%;
    }

    .FooterContainer{
        flex-direction: column;
    }

    .FooterStoreLinksWrapper{
        gap: 30px;
    }
}

@media (max-width: 457px){
    .FooterContainer{
        padding: 50px 20px;
    }
    .FooterLogoWrapper{
        flex-direction: column;
        align-items: center;
    }

    .FooterLinksWrapper{
        flex-direction: column;
        gap: 30px;
    }

    .FooterImageLinks{
        flex-direction: column;
    }

    .FooterStoreLinksWrapper{
        gap: 20px;
    }

    .FooterLinksContainer{
        width: 100%;
        align-items: center;
    }

    .FooterSocialLinksContainer{
        width: 100%;
        align-items: center;
    }

    
    .FooterLinksContainer a:not(:last-child){
        padding-right: 0;
    }


}