.AdminPageWrapper{
    width: 100vw;
    min-height: 100vh;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    gap: 120px;
}

.AdminContentContainerHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

.CrossButton{
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.AdminContainerName{
    width: 45%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 20px;
}

.AdminContainerName input{
    height: unset;
}

.AdminContainerName button{
    border-radius: 1000px;
    padding: 11px 20px;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    gap: 10px;

    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
    background: #ff2f55;

    font-weight: 700;
    font-size: 14px;
    color: #fff;

    border: none;
    outline: none;
    cursor: pointer;
}

.GenerAdminChooser{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10px;

    white-space: nowrap;

    padding: 15px;
    border: none;
    border-radius: 20px;
    background: #fff;

    font-weight: 400;
    font-size: 14px;
    line-height: 132%;
    color: #303030;
    transition: 0.3s;

    outline: none;
}

.GenerAdminChooser:hover{
    transition: 0.3s;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.09), 0 10px 6px 0 rgba(0, 0, 0, 0.05), 0 18px 7px 0 rgba(0, 0, 0, 0.01), 0 29px 8px 0 rgba(0, 0, 0, 0);
}

@media (hover: hover){
    .AdminContainerName button:hover{
        background: #ff5875;
    }

    .GenerAdminChooser:hover{
        cursor: unset;
    }
}

@media (max-width: 480px){
    .AdminContainerName{
        width: 100%;
        flex-direction: column;
    }
}
