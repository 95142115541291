.AddPagesComponentWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 40px;
    width: 100%;
}

.AddPagesComponentHeader{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    gap: 20px;

    width: 100%;
}

.AddPagesComponentHeaderLeftPart{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 15px;
}

.AddPagesComponentHeaderLabel{
    font-weight: 400;
    font-size: 20px;
    line-height: 132%;
    color: #303030;
}

.AddPagesComponentHeaderButtonWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 20px;
}

.AddPagesComponentHeaderAddPagesButton{
    position: relative;
    border-radius: 1000px;
    padding: 15px 35px;

    background: #303030;
    font-weight: 700;
    font-size: 16px;
    color: #fff;

    cursor: pointer;
}

.AddPagesComponentHeaderAddPagesButton input{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;

    cursor: pointer;
}

.AddPagesComponentHeaderDeleteButton{
    border: 1px solid #303030;
    border-radius: 1000px;
    padding: 15px 35px;

    font-weight: 700;
    font-size: 16px;
    color: #303030;

    cursor: pointer;
}

.AddPagesComponentContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    border-radius: 27px;
    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);
    background: #fff;

    height: 668px;
    width: 100%;
    padding: 30px;

    overflow: hidden auto;
}

.PagePreview{
    border: 1px solid #303030;
    border-radius: 20px;
    width: 204px;
    height: 289px;

    overflow: hidden;
}

.PagePreview img{
    width: 100%;
    height: 100%;

    object-fit: cover;
}

@media (max-width: 480px){
    .AddPagesComponentHeaderButtonWrapper{
        flex-direction: column;
        width: 100%;
    }

    .AddPagesComponentHeaderDeleteButton{
        width: 100%;
    }

    .AddPagesComponentHeaderButtonWrapper{
        width: 100%;
    }

    .EpisodeRedactCreateEpisodeButton{
        width: 100%;
    }

    .AddPagesComponentHeaderLeftPart{
        width: 100%;
    }

    .AddPagesComponentHeaderAddPagesButton{
        width: 100%;
    }
}