*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

body{
  background: #EAEFF0;
  overflow-y: scroll;
  overflow-x: hidden;
}

#root{
  display: flex;
  justify-content: center;
}

.App{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #EAEFF0;
  width: 100%;
}

.AppWrapp{
display: flex;
justify-content: center;
flex-direction: column;
background-color: #fff;
width: 768px;
height: 100%;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

a {
  text-decoration: none;
  color: unset;
}