.ComicsReadPageWrapper{
    width: 100vw;
    max-width: 1920px;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    gap: 120px;
}

.ComicsReadPageContainerGlobal{
    max-width: 1288px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    padding: 0 20px;
}

.ComicsReadPageAuthorWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    max-width: 1288px;
    width: 100%;
    padding: 15px;

    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);
    background: #fff;
    border-radius: 27px;
}

.ComicsReadPageAuthorContainer{
    max-width: 1288px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.ComicsReadPageAuthorLinks{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 10px;
}

.ComicsReadPageAuthorLinks a svg{
    height: 40px;
}

.ComicsReadPageContainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    gap: 30px;
    width: 100%;
    max-width: 1288px;
}

.ComicsReadPageEpisodeWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 20px;

    width: 100%;
}

.ComicsReadPageEpisodeWrapper a{
    width: 100%;
}

.ComicsReadPageTitleInfo{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 20px;

    width: 100%;
    padding: 30px;

    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);
    background: #fff;
    border-radius: 27px;
}

.ComicsReadPageTitleInfoStatistic{
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    gap: 20px;
}

.ComicsReadPageTitleInfoStatisticView{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

}

.ComicsReadPageTitleInfoTitle{
    font-weight: 700;
    font-size: 25px;
    line-height: 132%;
    text-transform: uppercase;
    color: #303030;
}

.ComicsReadPageTitleInfoDescription{
    font-weight: 400;
    font-size: 20px;
    line-height: 132%;
    color: #303030;
}

@media (max-width: 1024px){
    .ComicsReadPageContainer{
        flex-direction: column-reverse;
    }
}

@media (max-width: 480px){
    .ComicsReadPageAuthorContainer{
        flex-direction: column;
    }

    .SubscribeCardWrapper{
        width: 100%;
    }
}