.EpisodeReadPageWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    width: 100vw;

    min-height: 100vh;
}

.EpisodeReadPageControlPanel{
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    transition: top 0.3s;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: 40px;

    backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.4);

    padding-inline: 20px;
}

.EpisodeReadPageControlPanelTitle{
    font-weight: 400;
    font-size: 16px;
    line-height: 122%;
    color: #fff;
}

.EpisodeReadPagePage img{
    width: 100%;
    height: 100%;
    object-fit: inherit;
}