.InputTextForTitleTextArea{
    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);
    background: #fff;

    border: none;
    border-radius: 27px;

    width: 100%;
    min-height: 233px;
    padding: 15px;

    font-weight: 400;
    font-size: 16px;
    line-height: 132%;
    color: #6a6a6a;

    font-family: "Inter", sans-serif;

    resize: none;
}

.InputTextForTitleTextArea:focus{
    outline: none;
}