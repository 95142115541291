.AuthPageWrapper{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 20px;

    width: 100%;
    max-width: 1920px;

    min-height: 100vh;
}

.FormAuthWrapp{
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    overflow: hidden;

    height: 500px;
    width: 800px;

    border-radius: 30px;

    background: #fff;
    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.1), 9px 10px 30px 0 rgba(27, 37, 39, 0.1), 38px 41px 55px 0 rgba(27, 37, 39, 0.09), 84px 91px 75px 0 rgba(27, 37, 39, 0.05), 150px 162px 88px 0 rgba(27, 37, 39, 0.01), 234px 253px 97px 0 rgba(27, 37, 39, 0);
}

.AuthOpen{
    justify-content: flex-start;
}

.RegOpen{
    justify-content: flex-end;
}

.BannerChanger{
    position: absolute;
    width: 50%;
    height: 100%;
  

    background: #ff2f55;

    padding: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    font-weight: 700;
    font-size: 28px;
    line-height: 127%;
    color: #fff;

       
    transition: 0.5s;
}

.BannerChangerAuth{
    top: 0;
    left: 50%;
}

.BannerChangerRegistration{
    top: 0;
    left: 0;
}

.AuthPageBody{
    font-weight: 400;
    font-size: 16px;
    line-height: 118%;
    color: #fff;
}

.AuthPageButton{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.AuthPageButton a{
    border-radius: 1000px;
    padding: 0 35px;
    width: 100%;
    border: none;
    background: #fff;
    font-size: 16px;
    color: #303030;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    
}

.AuthFormWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 20px;
    width: 50%;
    padding: 20px;

    height: 100%;

}

.AuthFormWrapper form{
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    gap: 10px;
}

.NameLogIn{
    width: 100%;
}

.AuthPageLogo{
    width: 100%;

    font-weight: 700;
    font-size: 50px;
    line-height: 132%;
    color: #303030;
}

.RegistrationFormWrapper{
    width: 50%;
    padding: 20px;

    height: 100%;
}

.logIn{
    border-radius: 1000px;
    padding: 15px 35px;

    width: 100%;

    border: none;
    cursor: pointer;

    font-weight: 700;
    font-size: 16px;
    color: #fff;

    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
    background: #ff2f55;
}

.logIn:hover{
    background: #ff5875;
}

.AgreementInfo{
    position: absolute;
    padding-top: 10px;
    bottom: 20px;
    max-width: 700px;

    text-align: center;

    font-weight: 700;
    font-size: 16px;
    color: #303030;
}

.AgreementInfo a{
    color: #ff2f55;
}


@media (max-width: 480px){
    .AuthPageWrapper{
        flex-direction: column;
        gap: 20px;
    }
    .FormAuthWrapp{
        flex-direction: column;
        height: 800px;
        width: 80%;
    }

    .BannerChanger{
        width: 100%;
        height: 50%;
        min-height: 420px;
    }

    .AuthFormWrapper{
        width: 100%;
        height: 50%;

        min-height: 420px;
    }

    .BannerChangerAuth{
        top: 50%;
        left: 0;
    }
    
    .BannerChangerRegistration{
        top: 0;
        left: 0;
    }

    .AgreementInfo{
        bottom: 0;
        color: #fff;
        position: sticky;
        height: 50px;
        width: 100%;
        font-size: 12px;
        backdrop-filter: blur(20px);
        background: rgba(0, 0, 0, 0.4);
    }
}



