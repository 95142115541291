.ExclusivePageWrapper{
    width: 100vw;
    max-width: 1920px;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    gap: 120px;
}

.ExclusivePageContainer{
    max-width: 1288px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.PopularPageContainerHeader{
    font-weight: 700;
    font-size: 50px;
    line-height: 132%;
    text-align: center;
    color: #303030;
}

.PopularPageContainerToons{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 20px;
}

.PopularPageContainerHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ToonContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;

    gap: 20px;
}

@media (max-width: 1350px){
    .ExclusivePageContainer{
        padding: 0px 20px;
    }
}

@media (max-width: 480px){
    .ToonContainer{
        flex-direction: column;
    }
}