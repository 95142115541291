.AuthorPageWrapper{
    width: 100vw;
    min-height: 100vh;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    gap: 120px;
}

.AuthorPageContainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    
    padding: 0 20px;
    min-height: 42%;

    max-width: 1288px;
}

.MainAuthorInfo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.AuthorInfoContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    gap: 30px;
    width: 100%;
}

.AuthorComicsesContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 15px;
    flex-wrap: wrap;
    width: 100%;
}

.MainAuthorInfo button{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    padding: 11px 20px;
    background: #ff2f55;

    outline: none;
    border: none;
    cursor: pointer;

    font-weight: 700;
    font-size: 14px;
    color: #fff;
}

.SubscribeCardWrapper button:hover{
    background: #ff5875;
}