.GenrePageWrapper{
    width: 100vw;
    max-width: 1920px;

    min-height: 100vh;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    gap: 120px;
}

.GenrePageContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 50px;

    width: 100%;
    max-width: 1288px;

}

.GenreFilterButtonsWrapp{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: 20px;

    width: 100%;
    overflow: overlay;
    padding: 20px;
}

.GenerChooser{
    display: flex;
    justify-content: center;
    align-items: center;

    white-space: nowrap;

    padding: 15px;
    border: none;
    border-radius: 20px;

    cursor: pointer;
    background: #fff;

    font-weight: 400;
    font-size: 14px;
    line-height: 132%;
    color: #303030;
    transition: 0.3s;

    outline: none;
}

.GenerChooser.Active{
    transition: 0.3s;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.09), 0 10px 6px 0 rgba(0, 0, 0, 0.05), 0 18px 7px 0 rgba(0, 0, 0, 0.01), 0 29px 8px 0 rgba(0, 0, 0, 0);
}

.GenerChooser:hover{
    transition: 0.3s;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.09), 0 10px 6px 0 rgba(0, 0, 0, 0.05), 0 18px 7px 0 rgba(0, 0, 0, 0.01), 0 29px 8px 0 rgba(0, 0, 0, 0);
}

.GenrePageComicsWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: 20px;

    flex-wrap: wrap;
    width: 100%;
}

@media (max-width: 1288px){
    .GenrePageComicsWrapper{
        padding: 0 20px;
    }
}