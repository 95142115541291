.AccountPageWrapper{
    width: 100vw;
    min-height: 100vh;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    gap: 120px;
}

.AccountContentContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    
    min-height: 42%;

    max-width: 1288px;
    padding: 0 20px;
}

.AccountContentContainerHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    width: 100%;

}


.AccountContentContainerHeaderLabel{
    font-weight: 700;
    font-size: 28px;
    line-height: 132%;
    color: #303030;
}

.AccountContentContainerHeaderButtonContainer a{
    border-radius: 1000px;
    padding: 11px 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    gap: 10px;

    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
    background: #ff2f55;

    font-weight: 700;
    font-size: 14px;
    color: #fff;
}

.AccountContentContainerHeaderButtonContainer a:hover{
    background: #ff5875;
}

.AccountContentContainerHeaderButtonContainer button{
    border-radius: 1000px;
    padding: 11px 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    gap: 10px;

    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
    background: #ff2f55;

    font-weight: 700;
    font-size: 14px;
    color: #fff;

    border: none;
    outline: none;
    cursor: pointer;
}

.ComicsTitleWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;

    width: 100%;
}

.ComicsReadPageTitleInfoVerticalPreview{
    width: 100%;
}

.ComicsReadPageTitleInfoVerticalPreview img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);
}

.ComicsReadPageTitleInfoTitle{
    font-weight: 700;
    font-size: 25px;
    line-height: 132%;
    text-transform: uppercase;
    color: #303030;
}

.AccountContentNavigation{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    width: 100%;

    gap: 10px;
}

.AccountContentNavigationLink{
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 15px;
    border: none;
    border-radius: 20px;

    cursor: pointer;
    background: #fff;

    font-weight: 400;
    font-size: 14px;
    line-height: 132%;
    color: #303030;
    transition: 0.3s;

    outline: none;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.09), 0 10px 6px 0 rgba(0, 0, 0, 0.05), 0 18px 7px 0 rgba(0, 0, 0, 0.01), 0 29px 8px 0 rgba(0, 0, 0, 0);
}

.AccountContentNavigationLink:hover{
    background: #303030;
    color: #fff;
    transition: 0.3s;
}

.AccountContentNavigationLink.Active{
    background: #303030;
    color: #fff;
    transition: 0.3s;
}

.ComicsTitleWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;

    gap: 40px;
    width: 100%;
}

.ProfileContainer{
    max-width: 500px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 20px;
}

.ProfileContainerName{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 20px;
}

.ProfileItem{
    background: #fff;

    padding: 15px;
    border: none;
    border-radius: 20px;

    font-weight: 400;
    font-size: 14px;
    line-height: 132%;
    color: #303030;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.09), 0 10px 6px 0 rgba(0, 0, 0, 0.05), 0 18px 7px 0 rgba(0, 0, 0, 0.01), 0 29px 8px 0 rgba(0, 0, 0, 0);
}

.PhotoProfileContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.PhotoProfile{
    position: relative;
    border: 1px solid #303030;
    border-radius: 100000002004087730000px;
    width: 89px;
    height: 89px;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.09), 0 10px 6px 0 rgba(0, 0, 0, 0.05), 0 18px 7px 0 rgba(0, 0, 0, 0.01), 0 29px 8px 0 rgba(0, 0, 0, 0);
    background: #4a6cf7;
    overflow: hidden;

}

.PhotoProfile img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.PhotoProfile input{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: 100000002004087730000px;
    cursor: pointer;
}

.ComicsTitleWrapper center{
    width: 100%;

    font-weight: 700;
    font-size: 50px;
    line-height: 132%;
    text-align: center;
    color: #303030;
}

@media (hover: hover){
    .AccountContentContainerHeaderButtonContainer button:hover{
        background: #ff5875;
    }
}

@media (max-width: 480px){
    .AccountContentContainerHeader{
        flex-direction: column;
    }
}