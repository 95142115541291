.InputTypePasswordWrapper{
    position: relative;
    width: 100%;
}

.ButtonEye{
    border: none;
    background: none;

    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 8px;

    transition: 0.5s;
}



.InputTypePassword{
    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);
    background: #fff;

    border: none;
    border-radius: 1000000000px;

    width: 100%;
    height: 32px;

    padding: 15px;

    padding-right: 30px;
}

.InputTypePassword:focus{
    outline: none;
}