#error{
    position: fixed;
    top: 120px;
    right: 50px;
    z-index: 20;
}

#success{
    position: fixed;
    top: 120px;
    right: 50px;
    z-index: 20;
}

#info{
    position: fixed;
    top: 120px;
    right: 50px;
    z-index: 20;
}

.error{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    width: max-content;

    position: absolute;
    top: 0;
    right: -1000px;

    padding: 15px 35px;
    font-weight: 700;
    font-size: 16px;
    color: #fff;

    border-radius: 1000px;
    
    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
    background: #ff2f55;
    transition: 1s;
}

.success{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    width: max-content;

    position: absolute;
    top: 0;
    right: -1000px;

    padding: 15px 35px;
    font-weight: 700;
    font-size: 16px;
    color: #fff;

    border-radius: 1000px;
    
    background: #00ff85;
    transition: 1s;
}

.show{
    right: 0;
    transition: 1s;
}