.MakeYourOwnPageWrapper{
    width: 100vw;
    max-width: 1920px;

    min-height: 100vh;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    gap: 120px;
}

.FormToMakeYourOwnWrapper{
    width: 100%;
    max-width: 1288px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 100px;
    padding: 0 20px;
}

.FormToMakeYourOwnHeader{
    font-weight: 700;
    font-size: 50px;
    line-height: 132%;
    text-align: center;
    color: #303030;
}

.PageFormIndicators{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    gap: 70px;
    width: 100%;
    padding: 0 20px;
}

.PageFormIndicator{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    gap: 25px;

    font-weight: 700;
    font-size: 25px;
    line-height: 132%;
    text-transform: uppercase;
    color: #303030;
}

.PageFormIndicator span{
    position: relative;
    border-radius: 100%;
    width: 47px;
    height: 47px;

    border: 1px solid  #6a6a6a;
}

.PageFormIndicator span center{
    margin-top: 4px;
    font-weight: 700;
    font-size: 25px;
    line-height: 132%;
    text-transform: uppercase;
    color: #6a6a6a;

    height: 33px;
}

.PageFormIndicator.Active span{
    border: none;
    background: #ff2f55;
    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
}

.PageFormIndicator.Active span center{
    color: #fff;

}

.FormToCreateTitle{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 80px;
    width: 100%;
}

.FormToCreateTitleImgContent{
    max-width: 216px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 60px;
}

.FormToCreateTitleMainContent{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 60px;
    width: 100%;
}

.GenreWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 106px;

    width: 100%;
}

.TitleNameWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    width: 100%;
}

.DescriptionTitleWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    width: 100%;
}

.WarnAndSubmitWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 30px;
    width: 100%;
}

.WarnAndSubmitContent{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 20px;
    width: 100%;
}

.WarnAndSubmitHeader{
    font-weight: 700;
    font-size: 25px;
    line-height: 132%;
    color: #fd2f55;
}

.WarnAndSubmitInfo{
    font-weight: 400;
    font-size: 20px;
    line-height: 132%;
    color: #303030;
}

.WarnAndSubmitInfo span{
    color: #fe2f55;
}

.WarnAndSubmitWrapper button{
    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
    background: #ff2f55;

    border-radius: 1000px;
    padding: 15px 35px;
    
    border: none;
    cursor: pointer;

    font-weight: 700;
    font-size: 16px;
    color: #fff;
}

.WarnAndSubmitWrapper button:hover{
    background: #ff5875;
}

.EpisodeCreateDHeader{
    font-weight: 700;
    font-size: 28px;
    line-height: 132%;
    color: #303030;
}

.EpisodeCreateDHeader span{
    font-weight: 400;
    color: #6a6a6a;
}


@media (max-width: 1024px){
    .GenreWrapper{
        flex-direction: column;
        gap: 30px;
    }
}

@media (max-width: 720px){
    .FormToCreateTitle{
        flex-direction: column-reverse;
        gap: 30px;
    }

    .FormToCreateTitleImgContent{
        max-width: 100%;
        width: 100%;
    }
}

@media (max-width: 480px){
    .PageFormIndicators{
        flex-direction: column;
        gap: 30px;
    }
}