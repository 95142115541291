.EpisodeRedactWrapper{
    width: 100vw;
    max-width: 1920px;

    min-height: 100vh;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    gap: 120px;
}

.EpisodeRedactCreateEpisodeButton{
    height: 49px;
    border: none;
    padding: 15px 35px;
    border-radius: 1000px;

    transition: 0.5s;

    color: #fff;

    cursor: pointer;

    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
    background: #ff2f55;
}

.Arrow{
    position: absolute;
    width: 52px;
    height: 52px;

    border: none;

    border-radius: 100%;
    background: #ff2f55;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
}

.Left{
    left: -75px;
}

.Right{
    right: -75px;
}

.Right svg{
    transform: rotate(180deg);
}

.Disable{
    opacity: 0.5;
}

@media(hover :hover){
    .EpisodeRedactCreateEpisodeButton:hover{
        background: #ff5875;
    }

    .Arrow:hover{
        background: #ff5875;
    }
}


@media (max-width: 1440px){
    .Left{
        left: 0;
    }
    
    .Right{
        right: 0;
    }
}

@media (max-width: 480px){
    .Left{
        top: 130px;
    }
    
    .Right{
        top: 130px;
    }
}