.ButtonBackNo{
    background: none;
    border: 1px solid #303030;
    border-radius: 1000px;

    transition: 0.5s;

    height: 49px;
    padding: 15px 35px;

    cursor: pointer;
}

@media(hover :hover){
    .ButtonBackNo:hover{
        background: #303030;
        color: #fff;
    }
}