.HeaderWrapper{
    height: 100px;
    min-height: 100px;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.HeaderContent{
    width: 100%;
    max-width: 1920px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 0 100px;
}

.HeaderLinkWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    gap: 45px;

    font-weight: 700;
    font-size: 16px;
    line-height: 181%;
    color: #6a6a6a;

}

.HeaderLinkWrapper a{
    transition: 0.5s;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

}

.HeaderLinkWrapper a span{
    width: 0;
    height: 3px;

    transition: 0.5s;
    border-radius: 1000px;
    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
    background: #ff2f55;
}

.HeaderLinkWrapper a:hover{
    color: #303030;
}

.HeaderLinkWrapper a:hover span{
    transition: 0.5s;
    width: 57px;
}

.HeaderLinkWrapper a:focus{
    color: #303030;
}

.HeaderLinkWrapper a:focus span{
    transition: 0.5s;
    width: 57px;
}

.HeaderLinkActive span{
    width: 57px !important;
    height: 3px;
}

.HeaderLinkActive{
    color: #303030;
}

.RightPartHeader{
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 20px;
    height: 40px;
    gap: 5px;

    cursor: pointer;

    transition: 0.5s;
    
}

.RightPartHeader::before{
    position: absolute;
    content: " ";
    top: 10px;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 1000px;
    background: black;
    transition: 0.5s;
}

.RightPartHeader span{
    content: " ";
    width: 100%;
    height: 3px;
    border-radius: 1000px;
    background: black;
    transition: 0.5s;
}

.RightPartHeader::after{
    position: absolute;
    content: " ";
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 1000px;
    background: black;
    transition: 0.5s;
}

.RightPartHeader.Active::before{
    transform: rotate(45deg);
    transition: 0.5s;
    top: 50%;

}

.RightPartHeader.Active::after{
    transform: rotate(-45deg);
    top: 50%;
    transition: 0.5s;
}

.RightPartHeader.Active span{
    opacity: 0;
    transition: 0.5s;
}

.HeaderButtonsContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    position: relative;

    gap: 16px;
}

.HeaderButtonsWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    gap: 16px; 
    cursor: pointer;
}

.HeaderButtonsWrapper svg{
    transition: 0.5s;
}

.HeaderButtonsNameInfo{
    font-weight: 700;
    font-size: 13px;
    line-height: 223%;
    color: #303030;
}

.HeaderButtonsPhotoProfile{
    border: 1px solid #303030;
    border-radius: 100000002004087730000px;
    width: 49px;
    height: 49px;
    overflow: hidden;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.09), 0 10px 6px 0 rgba(0, 0, 0, 0.05), 0 18px 7px 0 rgba(0, 0, 0, 0.01), 0 29px 8px 0 rgba(0, 0, 0, 0);
    background: #4a6cf7;
}

.HeaderButtonsPhotoProfile img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.HeaderButtonsMenu{
    position: absolute;
    top: -230px;
    opacity: 0;
    left: 0;
    z-index: 1;

    padding: 15px;
    border-radius: 20px;

    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);
    background: #fff;

    font-weight: 700;
    font-size: 16px;
    line-height: 181%;
    color: #6a6a6a;
    transition: 0.5s;
}

.HeaderButtonsMenu.Open{
    opacity: 1;
    top: 57px;
    transition: 0.5s;
}

.HeaderButtonsMenu a span{
    width: 0;
    height: 3px;

    transition: 0.5s;
    border-radius: 1000px;
    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
    background: #ff2f55;
}

.HeaderButtonsMenu a:hover{
    color: #303030;
}

.HeaderButtonsMenu a:hover span{
    transition: 0.5s;
    width: 57px;
}

.HeaderButtonsMenu{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 5px;

    font-weight: 700;
    font-size: 16px;
    line-height: 181%;
    color: #6a6a6a;

}

.HeaderButtonsMenu a{
    transition: 0.5s;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

}

.HeaderButtonsMenuItem{
    transition: 0.5s;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    cursor: pointer;
}

.HeaderButtonsMenuItem:hover{
    color: #303030;
}

.HeaderButtonsMenuItem span{
    width: 0;
    height: 3px;

    transition: 0.5s;
    border-radius: 1000px;
    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
    background: #ff2f55;
}

.HeaderButtonsMenuItem:hover span{
    transition: 0.5s;
    width: 47px;
}

.BurgerMenu{
    display: none;
    position: absolute;

}

.BurgerLinkWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;

    gap: 15px;

    font-weight: 700;
    font-size: 16px;
    line-height: 181%;
    color: #6a6a6a;

}

.BurgerLinkWrapper a{
    transition: 0.5s;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

}

.BurgerLinkWrapper a span{
    width: 0;
    height: 3px;

    transition: 0.5s;
    border-radius: 1000px;
    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
    background: #ff2f55;
}

.BurgerButtonsWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    width: 100%;
    gap: 16px; 
    cursor: pointer;
}

.BurgerButtonsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    position: relative;

    width: 100%;

    gap: 16px;
}


@media (max-width: 1280px){
    .HeaderContent{
        padding: 0 20px;
    }
}

@media (max-width: 1024px){
    .RightPartHeader{
        display: flex;
    }

    .HeaderButtonsWrapper{
        display: none;
    }

    .HeaderLinkWrapper{
        display: none;
    }

    .HeaderButtonsContainer{
        display: none;
    }

    .BurgerMenu{
        opacity: 0;
        top: 0px;
        left: 0;
        padding-top: 20px;
        padding-left: 12px;
        display: flex;
        visibility: hidden;
        position: absolute;
        flex-direction: column;
        align-items: flex-start;

        gap: 30px;
        height: 100vh;
        width: 100vw;

        backdrop-filter: blur(10px);
        transition: 0.5s;
    }

    .BurgerMenu.Active{
        visibility: visible;
        opacity: 1;
        transition: 0.5s;
    }
}

