.CheckButtonWrapper label{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    cursor: pointer;
}

.realCheckBox {
	width: 0;
	height: 0;
	opacity: 0;
	position: absolute;
	z-index: -1;

}

.realCheckBox:focus + .visibleCheckBox {
	background: #f2f2f2;

}

.visibleCheckBox {
	position: relative;
	display: inline-block;
    border-radius: 10px;
    width: 30px;
    height: 30px;
    overflow: hidden;
	vertical-align: sub;
    border: 1px solid #6a6a6a;
    transition: 0.3s;
}

.visibleCheckBox::before {
	content: '';

	/* Рисуем галочку */
	display: inline-block;
	width: 30px;
	height: 30px;


	/* Выравниваем по центру */
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	transition: 0.2s;
}

/*
Активируем фейковый / декоративный чекбокс
*/

.realCheckBox:checked + .visibleCheckBox::before {
    background: #ff2f55;
    border: none;
    background-image: url('./images/Check.svg');
    background-size: inherit;
	background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s;
}

.realCheckBox:checked + .visibleCheckBox{
    border: none;
    box-shadow: 0 0 0 0 rgba(255, 47, 85, 0.2), 0 3px 6px 0 rgba(255, 47, 85, 0.2), 0 11px 11px 0 rgba(255, 47, 85, 0.17), 0 24px 15px 0 rgba(255, 47, 85, 0.1), 0 43px 17px 0 rgba(255, 47, 85, 0.03), 0 68px 19px 0 rgba(255, 47, 85, 0);
}

.realCheckBox:hover + .visibleCheckBox{
    background: #ff5875;
    transition: 0.3s;
}