.MainPageWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.MainPageWrapperHelloContentWrapper{
    width: 100vw;
    max-width: 1920px;
    z-index: 0;

    height: 610px;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    gap: 20px;

    background: #EAEFF0;

}

.MainPageHelloContainer{

    padding: 100px 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    gap: 20px;
}

.MainPageWrapperContentContainer{
    position: relative;
    width: 100vw;
    max-width: 1920px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    min-height: 80vh;
    background: #ff2f55;

    padding: 50px 0;
}

.DescriptionProjectContainer{
    width: 100%;
    max-width: 1288px;

    background: #EAEFF0;

    border-radius: 20px;

    padding: 20px;
}

.MainPageHello{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    gap: 34px;
}

.MainPageHelloHeader{
    font-weight: 700;
    font-size: 50px;
    line-height: 132%;
    color: #303030;
    text-align: center;
}

.MainPageHelloBody{
    font-weight: 500;
    font-size: 18px;
    line-height: 161%;
    color: #6a6a6a;
    text-align: center;
}

.DescriptionPointsContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: 10px;
}

.PointDesription{
    font-weight: 500;
    font-size: 18px;
    line-height: 161%;
    color: #303030;
}

.PointDesriptionText{
    font-weight: 500;
    font-size: 18px;
    line-height: 161%;
    color: #6a6a6a;
}

.ButtonContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: 20px;
}

@media (max-width: 1919px){
    .MainPageHelloContainer{
        left: 15.9vw;
    }
}

@media (max-width: 1360px){
    .MainPageHelloContainer{
        left: 100px;
    }
}

@media (max-width: 1280px){
    .MainPageHelloContainer{
        left: 20px;
    }

    .MainPageWrapperHelloContentWrapper{
        height: 900px;
    }
}

@media (max-width: 760px){
    .MainPageHelloContainer{
        width: 100%;
    }

    .MainPageWrapperHelloContentWrapper{
        height: 1000px;
    }
    
}

@media (max-width: 480px){
    .ButtonContainer{
        flex-direction: column;
    }

    .MainPageHelloHeader{
        font-size: 32px;
    }

    .MainPageHelloBody{
        font-size: 14px;
    }

    .MainPageWrapperHelloContentWrapper{
        height: 700px;
    }
}

/* @media (max-width: 390px){
    .MainPageWrapperHelloContentWrapper{
        height: 1100px;
    }
} */