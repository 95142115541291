.ComicsCardWrapper{
    border-radius: 27px;
    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);
    background: #fff;

    padding: 30px 40px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    width: 100%;
}

.ComicsCardInfoContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 30px;
}

.ImgComicsContainer{
    border: 1px solid #303030;
    border-radius: 20px;
    overflow: hidden;

    width: 147px;
    height: 147px;
}

.ImgComicsContainer img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.ComicsCardInfoContainerBody{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    max-width: 75%;

    gap: 15px;
}

.ComicsCardInfoContainerBodyTitle{
    font-weight: 400;
    font-size: 25px;
    line-height: 132%;
    color: #303030;
}

.ComicsCardInfoContainerBodyEpisodeContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 10px;
}

.AddEpisodeItem{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
}

.ComicsCardButtonContainer{
    position: absolute;
    top: 30px;
    right: 30px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    gap: 10px;
}

.ComicsCardButtonContainer a{
    border: 1px solid #303030;
    border-radius: 13px;
    width: 49px;
    height: 49px;
    background: #f2f2f2;

    display: flex;
    justify-content: center;
    align-items: center;
}

.ComicsCardButtonContainer a svg{
    width: 21px;
    height: 21px;
}

.ComicsCardButtonContainer button{
    border: 1px solid #303030;
    border-radius: 13px;
    width: 49px;
    height: 49px;
    background: #f2f2f2;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.ComicsCardButtonContainer button svg{
    width: 21px;
    height: 21px;
}

@media (max-width: 1024px){
    .ComicsCardInfoContainerBodyTitle{
        width: 70%;
    }
}

@media (max-width: 720px){
    .ComicsCardInfoContainerBodyTitle{
        width: 100%;
    }
    .ComicsCardInfoContainer{
        flex-direction: column;
    }

    .ComicsCardButtonContainer{
        flex-direction: column;
    }

    .ComicsCardInfoContainerBodyEpisodeContainer{
        flex-wrap: wrap;
    }
}